import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  titel="اتصل بنا";
  titel2 ="خدمات العملاء  "

  
  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0)

  }

}
