import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appexpress',
  templateUrl: './appexpress.component.html',
  styleUrls: ['./appexpress.component.scss']
})
export class AppexpressComponent implements OnInit {

  
  titel="خدمات العملاء ";
  titel2 ="خدمة الذهبي اكسبريس: "

  constructor() { }

  ngOnInit() {

    window.scrollTo(0, 0)


  }

}
