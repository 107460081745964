import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fatf',
  templateUrl: './fatf.component.html',
  styleUrls: ['./fatf.component.scss']
})
export class FatfComponent implements OnInit {


  titel="سياسة الأمتثال في الشركة:";
  titel2 ="خدمات العملاء  "
  
  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0)

  }

}
