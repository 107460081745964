import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  titel="من نحن ";
  titel2 ="حول شركة العروي المصرفية "
  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0)

  }

}
