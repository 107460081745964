import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  titel="منظومة القيمة ";
  titel2 ="خدمات العملاء  "


  constructor() { }

  ngOnInit() {

    window.scrollTo(0, 0)

  }

}
