import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  
  titel="خدماتنا ";
  titel2 ="خدمات شركة العروي (الذهبي اكسبرس):"
  constructor() { }

  ngOnInit() {
    
    window.scrollTo(0, 0)
    
    
  }

}
