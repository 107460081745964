import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-goolelmap',
  templateUrl: './goolelmap.component.html',
  styleUrls: ['./goolelmap.component.scss']
})
export class GoolelmapComponent implements OnInit {

 
  constructor() { }
  
  ngOnInit() {
    
  }

}
