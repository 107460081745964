import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-img-contlist',
  templateUrl: './form-img-contlist.component.html',
  styleUrls: ['./form-img-contlist.component.scss']
})
export class FormImgContlistComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
