import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { SlideHomeComponent } from './slide-home/slide-home.component';
import { FooterComponent } from './footer/footer.component';
import { FeaturedServicesSectionComponent } from './featured-services-section/featured-services-section.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ServicesComponent } from './services/services.component';
import { FatfComponent } from './fatf/fatf.component';
import { MsgMangComponent } from './msg-mang/msg-mang.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { BranchesComponent } from './branches/branches.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { GALLERYComponent } from './gallery/gallery.component';
import { AppexpressComponent } from './appexpress/appexpress.component';
import { CardNwsComponent } from './card-nws/card-nws.component';
import { CurrencyComponent } from './currency/currency.component';
import { BranchesListComponent } from './branches-list/branches-list.component';
import { GoolelmapComponent } from './goolelmap/goolelmap.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { InternationalAgenciesComponent } from './international-agencies/international-agencies.component';
import { FormImgContlistComponent } from './form-img-contlist/form-img-contlist.component';
import { MultiItemGalleryComponent } from './multi-item-gallery/multi-item-gallery.component';
import { LoadingComponent } from './loading/loading.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SlideHomeComponent,
    FooterComponent,
    FeaturedServicesSectionComponent,
    AboutUsComponent,
    ServicesComponent,
    FatfComponent,
    MsgMangComponent,
    AboutComponent,
    ContactComponent,
    BranchesComponent,
    PrivacyComponent,
    GALLERYComponent,
    AppexpressComponent,
    CardNwsComponent,
    CurrencyComponent,
    BranchesListComponent,
    GoolelmapComponent,
    ScrollTopComponent,
    InternationalAgenciesComponent,
    FormImgContlistComponent,
    MultiItemGalleryComponent,
    LoadingComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
   
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
