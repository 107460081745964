import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class BranchesComponent implements OnInit {


  titel="فروعنا";
  titel2 ="خدمات العملاء  "


  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0)

  }

}
