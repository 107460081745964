import { AppexpressComponent } from './appexpress/appexpress.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { BranchesComponent } from './branches/branches.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FatfComponent } from './fatf/fatf.component';
import { ContactComponent } from './contact/contact.component';

 
const routes: Routes = [

  {path: 'home',component: HomeComponent},
  { path: 'services',component: ServicesComponent} ,
  { path: 'fatf',component:FatfComponent},
  { path: 'about', component: AboutComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'branches', component: BranchesComponent},
  { path: 'privacy', component: PrivacyComponent},
  { path: 'appex', component: AppexpressComponent},
  { path: '**', component: HomeComponent},
  {path: '', redirectTo: 'home', pathMatch: 'full'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
