import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-services-section',
  templateUrl: './featured-services-section.component.html',
  styleUrls: ['./featured-services-section.component.scss']
})
export class FeaturedServicesSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
